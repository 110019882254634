import { object } from 'prop-types';
import { removingWordFree } from '../../helpers';
import * as styles from './cost.module.scss';
import { Wrap } from '../sections-wrap';

function Cost({ data }) {
  return (
    <div className={styles.costContainer}>
      <Wrap>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: removingWordFree(data?.eaPracticeStep3Content)}}
        />
      </Wrap>
    </div>
  );
}

Cost.propTypes = {
  data: object,
};

Cost.defaultProps = {
  data: {},
};

export { Cost };
