import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Section } from '../components/common/practice-child-step/section';
import { Step } from '../components/step-section';
import { LegalJourney } from '../components/legal-journey';
import { Cost } from '../components/legal-journey-cost';
import * as styles from './templates.module.scss';

function PracticeChildStep3Template({ data }) {
  const stepData = data?.wpPage?.wpParent?.node;
  const currentUri = stepData.uri;

  return (
    <main className={styles.pageStyles}>
      <Section data={stepData} />
      <Step step={3} text="How will I pay for this?" />
      <LegalJourney steps={4} currentStep={3} uri={currentUri} />
      <Cost data={data?.wpPage?.template?.practiceStepConfigurationAllSteps} />
    </main>
  );
}

export const query = graphql`
  query wpChildStepTemplate3($uri: String) {
    wpPage(uri: {eq: $uri}) {
      title
      uri
      template {
        ... on WpTemplate_PracticeStep {
          practiceStepConfigurationAllSteps {
            eaPracticeStep3Content
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            uri
            template {
              ... on WpTemplate_PracticeChild {
                practiceChildConfiguration {
                  eaPracticeShortName
                }
              }
            }
            wpParent {
              node {
                ... on WpPage {
                  title
                  uri
                }
              }
            }
          }
        }
      }
      ...SeoPage
    }
  }
`;

PracticeChildStep3Template.propTypes = {
  data: object,
};

PracticeChildStep3Template.defaultProps = {
  data: {},
};

export default PracticeChildStep3Template;
export { Head } from '../components/seo/seo';
